/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Rubik Regular';
font-style: normal;
font-weight: normal;
src: local('Rubik Regular'), url('Rubik-Regular.woff') format('woff');
}


@font-face {
font-family: 'Rubik Italic';
font-style: normal;
font-weight: normal;
src: local('Rubik Italic'), url('Rubik-Italic.woff') format('woff');
}


@font-face {
font-family: 'Rubik Light';
font-style: normal;
font-weight: normal;
src: local('Rubik Light'), url('Rubik-Light.woff') format('woff');
}


@font-face {
font-family: 'Rubik Light Italic';
font-style: normal;
font-weight: normal;
src: local('Rubik Light Italic'), url('Rubik-LightItalic.woff') format('woff');
}


@font-face {
font-family: 'Rubik Medium';
font-style: normal;
font-weight: normal;
src: local('Rubik Medium'), url('Rubik-Medium.woff') format('woff');
}


@font-face {
font-family: 'Rubik Medium Italic';
font-style: normal;
font-weight: normal;
src: local('Rubik Medium Italic'), url('Rubik-MediumItalic.woff') format('woff');
}


@font-face {
font-family: 'Rubik Bold';
font-style: normal;
font-weight: normal;
src: local('Rubik Bold'), url('Rubik-Bold.woff') format('woff');
}


@font-face {
font-family: 'Rubik Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Rubik Bold Italic'), url('Rubik-BoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Rubik Black';
font-style: normal;
font-weight: normal;
src: local('Rubik Black'), url('Rubik-Black.woff') format('woff');
}


@font-face {
font-family: 'Rubik Black Italic';
font-style: normal;
font-weight: normal;
src: local('Rubik Black Italic'), url('Rubik-BlackItalic.woff') format('woff');
}