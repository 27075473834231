// Colors.
$backgroundColor:   #f9fafb;
$borderColor:       #e8ebf0;
$borderRadius:      4px;
$primaryDark:       #162e51; // blueDark.
$primary:           #22795A; // blue.
$primaryLight:      #27AAE1; // blueLight.
$primaryDust:       #dce4ef; // blueDust.
$secondaryDark:     #EAC200; // yellowDark.
$secondary:         #f2d13c; // yellow.
$secondaryLight:    #FFFAEE; // yellowLight.
$grayDark:          #323A45;
$grayMedium:        #A7AAAC;
$grayLight:         #dddddd;
$grayDust:          #eeeeee;
$headingColor:      #162e51;
$textColor:         #3B3B3B;
$linkColor:         #22795A;
$linkHoverColor:    #162e51;
$navBarBackgroundColor: #e79d1c;
$navBarLink:        #FFFFFF;
$navBarLinkActive:  #FFFFFF;
$navBarLinkHoverBack:   #023756;
$navBarLinkActiveBack:  #305e92;
$navBarLinkActiveHoverBack: #f2d13c;
$navBarLinkActiveHover: #FFFFFF;
$heroBackgroundColor: #22795A;;
$heroTextColor:     #FFFFFF;
$heroHeadingColor:  #FFFFFF;
$footerBackgroundColor: #2B5E98;
$footerText:        #FFFFFF;
$footerLink:        #FFFFFF;
$footerLinkHover:   #FFFFFF;

// Fonts.
$fontText: 'Rubik','Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
$fontHeading:'Rubik Bold','Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;

// Alerts/Messaging.
$info:          #039fce;
$warning:       #fc9905;
$danger:        #d9534f;
$success:       #46a546;

// File Formats.
$apiIcon:       #317daa;
$binIcon:       #7a7a00;
$csvIcon:       #0b4498;
$docIcon:       #6f5191;
$rdfIcon:       #e36504;
$jsonIcon:      #005051;
$kmlIcon:       #3F6EA5;
$pdfIcon:       #e0051e;
$pptIcon:       #CF452C;
$xmlIcon:       #d14900;
$xlsIcon:       #015924;
$zipIcon:       #3B0053;
$htmlIcon:      #317daa;
$dataIcon:      #666666;
